import React from 'react'
import dayjs from 'dayjs'

import { Typography, Spin, Checkbox } from 'antd'
import { PrescriptionItem } from './PrescriptionItem'
import { useDispenseUnitContext } from '../../../../context/DispenseUniteContext'

const { Text } = Typography

const CompoundIngredient = ({ ingredient }) => {
    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: '10px', textTransform: 'capitalize' }} key={ingredient.freeText || ingredient.name}>
            <div>{ingredient.freeText || ingredient.name},</div>
            <div>{ingredient.quantity} {ingredient.dispenseUnit}</div>
        </div>
    )
}

const renderCompoundIngredients = ingredients => {
    return ingredients.map(ingredient =>
        <PrescriptionItem
            label='Ingredients'
            style={{ gridColumnStart: 1, gridColumnEnd: 3, textTransform: 'none' }}
            value={<CompoundIngredient ingredient={ingredient} />}
        />
    )
}

const Medication = ({ prescription }) => {
    return (
        <div>
            { prescription.name }
            { !!prescription.schedule && prescription.schedule !== "0" && <span style={{ color: 'red' }}> (Schedule {prescription.schedule})</span> }
        </div>
    )
}

export const Prescription = ({ prescription, i, handleReadyToSign  }) => {
    const { dispenseUnits, getDispenseUnitById } = useDispenseUnitContext()

    const dispenseUnit = getDispenseUnitById(prescription.dispenseUnitId)
    const isControlledSubstance = prescription.schedule && prescription.schedule !== "0"

    const _handleReadyToSign = ({ target }) => {
        handleReadyToSign(prescription._id, target.checked)
    }

    return (
        <div style={{ padding: '0 50px' }}>
            <Spin spinning={!prescription || !dispenseUnits}>
                <Text style={{ fontSize: '1.05rem', fontWeight: 'bolder' }}> Medication {i + 1} </Text>
                <div style={{ marginTop: '30px', display: 'grid', gridTemplateColumns: '65% 35%', gridRowGap: '10px' }}>
                    <PrescriptionItem style={{ flexDirection: 'row' }} label='Drug Description' value={<Medication prescription={prescription}/>}/>
                    <PrescriptionItem style={{ flexDirection: 'row' }} label='Dosage' value={prescription.strength} />
                    {!!prescription.ingredients?.length && renderCompoundIngredients(prescription.ingredients)}
                    <PrescriptionItem style={{ flexDirection: 'row' }} label='Quantity' value={`${prescription.quantity} ${dispenseUnit?.Name}`} />
                    <PrescriptionItem style={{ flexDirection: 'row' }} label='Dispense Type' value={prescription.doseForm || prescription.quantityUnits} />
                    <PrescriptionItem style={{ flexDirection: 'row' }} label='Days Supply' value={prescription.daysSupply} />
                    <PrescriptionItem style={{ flexDirection: 'row' }} label='Refills' value={prescription.refills} />
                    <PrescriptionItem style={{ flexDirection: 'row' }} label='Substitutions' value={`${prescription.noSubstitutions ? 'No' : ''} Substitutions Allowed`} />
                    <PrescriptionItem style={{ flexDirection: 'row' }} label='Urgent' value={prescription.isUrgent ? 'Yes' : 'No'} />
                    <PrescriptionItem style={{ flexDirection: 'row' }} label='Effective Date' value={dayjs(prescription.startDate).format('MM/DD/YYYY')} />
                    <PrescriptionItem style={{ gridColumnStart: 1, gridColumnEnd: 3 }} label='Notes to Pharmacy' value={prescription.pharmacyNotes || 'None'} />
                    <PrescriptionItem style={{ gridColumnStart: 1, gridColumnEnd: 3, textTransform: 'none' }} label='Directions' value={prescription.directions} />
                </div>
                { !!isControlledSubstance && (
                        <div
                            style={{
                                justifySelf: 'center',
                                width: '150px',
                                padding: '10px',
                                fontWeight: 'bold',
                                background: 'rgba(123, 173, 126, 0.15)'
                            }}
                        >
                            <Text>Ready to sign?</Text>
                            <Checkbox size="large" style={{ marginLeft: '5px'}} checked={prescription.readyToSign} onClick={_handleReadyToSign} />
                        </div>
                    )}
            </Spin>
        </div>
    )
}
